<template>
  <div>
    <div v-if="showForm" class="wrapper">
      <div class="form">
        <Form ref="form" :defaultForm="defaultForm" />
      </div>
      <div class="btn">
        <van-button
          :loading="loading"
          type="primary"
          block
          @click="handleSubmit"
        >
          提交
        </van-button>
      </div>
    </div>
    <UserForm :visible="showAuth" @success="handleValSuccess" />
  </div>
</template>

<script>
import Form from "./components/Form.vue";
import UserForm from "../components/UserForm.vue";
import AuthMixin from "../mixin/UserAuth";
import { Notify } from "vant";
export default {
  data() {
    return {
      loading: false,
      defaultForm: null
    };
  },
  mixins: [AuthMixin],
  components: { Form, UserForm },
  created() {
    // this.getDedaultForm();
  },
  methods: {
    getDefaultForm() {
      return this.$store
        .dispatch("houseSurvey/postTalentHouseList", {
          contactPhone: this.contactPhone
        })

        .then((res) => {
          if (res.data) {
            this.defaultForm = res.data;
          }
        });
    },
    //手机号验证成功
    handleValSuccess(form) {
      if (form.phone) {
        this.suthSuccess(form.phone);
      }
    },
    //提交表单
    handleSubmit() {
      this.$refs.form.validate().then((form) => {
        this.loading = true;
        this.$store
          .dispatch("houseSurvey/postAddTalentHouse", form)
          .then((res) => {
            Notify({
              type: "success",
              message: "提交成功",
              background: " #07c160"
            });
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f3f3f3;
  padding: 0.2rem 0.15rem;
}

.form {
  margin-bottom: 0.5rem;
}
</style>
