const rules = {
  cmpyName: [
    {
      required: true,
      message: "请输入填报单位"
    },
    {
      max: 200,
      message: "填报单位最多200字"
    }
  ],
  contactPhone: [
    {
      required: true,
      message: "请输入联系方式"
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请检查手机号格式"
    }
  ],
  userName: [
    {
      required: true,
      message: "请输入填报人"
    },
    {
      max: 20,
      message: "填报人最多20字"
    }
  ],

  detailDTOS: {
    type: "array",
    required: true,
    defaultField: {
      type: "object",
      fields: {
        cmpyName: [
          {
            required: true,
            message: "请输入单位名称"
          },
          {
            max: 200,
            message: "单位名称最多200字"
          }
        ],
        cmpyAddress: [
          {
            required: true,
            message: "请输入单位位置"
          },
          {
            max: 200,
            message: "位置最多200字"
          }
        ],
        gcj02Lat: [
          {
            required: true,
            message: "请进行位置定位"
          }
        ],
        gcj02Lon: [
          {
            required: true,
            message: "请进行位置定位"
          }
        ],
        staffNum: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "员工总数必须是大于0的整数"
          }
        ],
        manageNum: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "管理人员数量必须是大于0的整数"
          }
        ],
        baseStaffNum: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "基层人员数量必须是大于0的整数"
          }
        ],
        manageSalary: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "管理人员收入水平必须是大于0的整数"
          }
        ],
        baseStaffSalary: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "基层人员收入水平必须是大于0的整数"
          }
        ],
        houseWholeDemand: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "套房类型房屋需求数必须是大于0的整数"
          }
        ],
        houseSingleDemand: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "单间类型房屋需求数必须是大于0的整数"
          }
        ],
        expectStaffNum: [
          {
            type: "integer",
            required: true,
            max: 99999999,
            min: 1,
            message: "预计5年后员工人数必须是大于0的整数"
          }
        ]
      }
    }
  }
};
export default rules;
