<template>
  <!-- 人才公寓需求数量统计 -->
  <section class="form-wrapper">
    <div>
      <van-form ref="form">
        <div class="header card">
          <div class="title">航空港区人才公寓需求统计表</div>
          <!-- <div class="notices">
            <p class="notice-item">*填写说明</p>
            <p class="notice-item">
              1、上报人负责多个小区时，可通过点击
              <span class="link">”添加更多房源”</span>
              ，进行多个小区房源上报
            </p>
            <p class="notice-item">
              2、上报人负责单个小区内有多种户型时，可通过点击
              <span class="link">“增加户型”</span>
              ，补充完善小区户型信息。
            </p>
          </div> -->
          <van-field
            v-model.trim="form.cmpyName"
            label="填报单位"
            placeholder="请输入填报单位"
            required
            maxlength="200"
          />
          <van-field
            v-model.trim="form.userName"
            label="填报人"
            placeholder="请输入填报人"
            required
            maxlength="20"
          />
          <van-field
            v-model.trim="form.contactPhone"
            label="联系方式"
            placeholder="请输入联系方式"
            required
            maxlength="11"
            readonly
            disabled
          />
        </div>

        <!--  房屋信息  -->
        <div>
          <!-- <van-divider
            content-position="left"
            :style="{
              color: '#3C37D2',
              borderColor: '#3C37D2',
              padding: '0rem 0.3rem'
            }"
          >
            企业信息
          </van-divider> -->
          <div
            v-for="(item, i) in form.detailDTOS"
            :key="i"
            class="plot-wrapper card"
          >
            <p class="plot-title">
              <span> # {{ i + 1 }} </span>
              <span
                @click="handleDelPlot(i)"
                v-show="form.detailDTOS.length > 1"
                class="del-btn"
              >
                删除企业
              </span>
            </p>
            <van-field
              v-model.trim="item.cmpyName"
              label="单位名称（企业/学校/医院）"
              placeholder="请输入单位名称"
              maxlength="200"
              required
            />
            <!-- <van-field
              v-model="item.villageAddress"
              label="小区位置"
              placeholder="请输入小区位置"
              maxlength="20"
              required
            /> -->
            <area-select
              v-model.trim="item.cmpyAddress"
              :fieldProps="{
                label: '位置',
                required: true,
                placeholder: '请输入企业位置'
              }"
              @poiSelect="(e) => handlePoiSelect(i, e)"
            ></area-select>
            <van-field
              v-model.number="item.staffNum"
              label="员工总数/人"
              placeholder="请输入员工总数"
              required
            />
            <van-field
              v-model.number="item.manageNum"
              label="管理人员数量/人"
              placeholder="请输入管理人员数量"
              required
            />
            <van-field
              v-model.number="item.baseStaffNum"
              label="基层人员数量/人"
              placeholder="请输入基层人员数量"
              required
            />
            <van-field
              v-model.number="item.manageSalary"
              label="管理人员收入水平(元/月)"
              placeholder="请输入管理人员收入水平"
              required
            />
            <van-field
              v-model.number="item.baseStaffSalary"
              label="基层人员收入水平(元/月)"
              placeholder="请输入基层人员收入水平"
              required
            />
            <van-field
              v-model.number="item.houseWholeDemand"
              label="套房类型房屋需求数/套"
              placeholder="请输入套房类型房屋需求数"
              required
            />
            <van-field
              v-model.number="item.houseSingleDemand"
              label="单间类型房屋需求数/间"
              placeholder="请输入单间类型房屋需求数"
              required
            />
            <van-field
              v-model.number="item.expectStaffNum"
              label="预计5年后员工人数/人"
              placeholder="请输入预计5年后员工人数"
              required
            />
          </div>
          <div>
            <van-button
              block
              plain
              icon="plus"
              type="primary"
              size="small"
              @click="handleAddPlot"
            >
              新增企业
            </van-button>
          </div>
        </div>
      </van-form>
    </div>
  </section>
</template>

<script>
import Schema from "async-validator";
import formRules from "./rules";
import VanFieldSelect from "@/components/VanFieldSelect";
import cloneDeep from "lodash/cloneDeep";
import AreaSelect from "../../components/AreaSelect.vue";
import Cookies from "js-cookie";
import { Dialog } from "vant";
//企业表单
const cmpyForm = {
  baseStaffNum: "",
  baseStaffSalary: "",
  cmpyAddress: "",
  cmpyName: "",
  gcj02Lat: "",
  gcj02Lon: "",
  houseSingleDemand: "",
  houseWholeDemand: "",
  manageNum: "",
  manageSalary: "",
  staffNum: ""
};
export default {
  data() {
    return {
      form: {
        cmpyName: "",
        contactPhone: "",
        userName: "",
        detailDTOS: [cloneDeep(cmpyForm)]
      }
    };
  },
  props: {
    defaultForm: Object
  },
  components: { VanFieldSelect, AreaSelect },
  computed: {},
  mounted() {
    if (this.defaultForm) {
      this.form = this.defaultForm;
    }
    const authPhone = Cookies.get("HouseSurveyPhone") || "";
    if (authPhone) {
      this.form.contactPhone = authPhone;
    }
  },
  methods: {
    handleAddPlot() {
      this.form.detailDTOS.push(cloneDeep(cmpyForm));
    },
    handleDelPlot(index) {
      if (this.form.detailDTOS.length == 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      Dialog.confirm({
        title: "提示",
        message: "确认要删除此企业吗？"
      }).then(() => {
        this.form.detailDTOS.splice(index, 1);
      });
    },
    handleAddHouseType(i) {
      this.form.detailDTOS[i].houseTypes.push(cloneDeep(houseTypeForm));
    },
    handleDelHouseType(i, j) {
      this.form.detailDTOS[i].houseTypes.splice(j, 1);
    },
    handlePoiSelect(i, poi) {
      this.form.detailDTOS[i].cmpyAddress = `${poi.address} ${poi.name}`;
      this.form.detailDTOS[i].gcj02Lat = `${poi.location.lat}`;
      this.form.detailDTOS[i].gcj02Lon = `${poi.location.lng}`;
    },
    validate() {
      const validator = new Schema(formRules);
      return new Promise((resolve, reject) => {
        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve({
              ...this.form
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0.2rem 0.3rem;
  overflow: hidden;
  margin-bottom: 0.3rem;
}
.title {
  font-size: 0.36rem;
  margin: 0.3rem 0;
  text-align: center;
  font-weight: bold;
}
.form-wrapper {
  // margin-top: 0.2rem;
  // background: #fff;
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.house-wrapper {
  padding: 0.1rem 0.15rem;
  .house-title {
    margin: 0.3rem 0;
    .add-btn {
      margin-left: 0.2rem;
    }
    // display: flex;
    // justify-content: space-between;
  }
  .house-form {
    padding-left: 0.15rem;
    overflow: auto;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid #dfdfdf;
    .del-house-btn {
      display: block;
      // margin-bottom: 0.2rem;
      padding: 0.2rem 0;
    }
  }
  .house-form-title {
    margin: 0.1rem 0;
    display: flex;
    justify-content: space-between;
  }
}
.plot-wrapper {
  // border-bottom: 1px solid #dfdfdf;
  padding: 0.2rem 0;
  margin-bottom: 0.2rem;
  .plot-title {
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
  }
}
.add-btn {
  color: #3c37d2;
}
.del-btn {
  color: red;
}
.notices {
  margin-top: 0.2rem;
  padding: 0.3rem;
  background-color: #fff;
  .notice-item {
    font-size: 0.3rem;
    line-height: 0.5rem;
    color: #ff0000;
    margin-bottom: 0.2rem;
  }
  .link {
    color: blue;
  }
}
</style>
